import React from "react"
import { Link } from "gatsby"
import Particles from "react-particles-js"

import particleOptions from "../assets/particles.json"

const NotFoundPage = () => (
  <div>
    <Particles className="particles" params={particleOptions} />
    <div className="outerContainer">
      <div className="mainContainer">
        <h1>404</h1>
        <h3>Looks like you're lost.</h3>
        <p>We can’t seem to find the page you’re looking for.</p>
        <p>
          Back to <Link to="/">home</Link>.
        </p>
      </div>
    </div>
  </div>
)

export default NotFoundPage
